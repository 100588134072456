module.exports = {
    language: {
        title: '切换到英文',
    },
    login: {
        AccountPasswordLogin: '账号密码登录',
        AuthenticationCodeLogin: '验证码登录(注册)',
        phoneNumber: '手机号',
        password: '密码',
        VerificationCode: '验证码',
        GetVerificationCode: '获取验证码',
        SignInNow: '立即登录',
        TheVerificationCodeCannotBeEmpty: '验证码不能为空',
        MobileNumberCannotBeEmpty: '手机号不能为空',
        PasswordCannotBeEmpty: '密码不能为空',
        LoginSucceeded: '登录成功',
        Reacquire: '重新获取',
        ResendAfter: 's后重发'
    },
    layout: {
        MerchantManagementCenter: '商户管理中心',
        signout: '退出',
        Goodmorning: '早上好',
        Goodnoon: '中午好',
        Goodafternoon: '下午好',
        Goodevening: '晚上好',
        Goodnight: '夜里好',
    },
    error: {
        Sorry: '很抱歉, 您访问的页面丢了.',
        return: '返回'
    },
    mendian: {
        Welcome: '欢迎您',
        BasicApplication: '基础应用',
        logic: '追求简单的运营逻辑首选',
        FreeTrial: '免费试用',
        day: '天',
        ApplicableScenarios: '适用场景',
        solutions: '行业深度解决方案',
        whole: '全部',
        development: '更多解决方案正在研发中',
        ComingSoon: '敬请期待',
        CreateShop: '创建商铺',
        EnterStoreName: '输入门店名称',
        StoreName: '店铺名',
        Successfully: '创建商铺成功',
        expire: '到期',
        ChangePassword: '修改密码',
        Myshop: '我的商铺',
        Eachshop: '每个商铺为独立运营的，会员是不通用的。每个商铺可以邀请合伙人一起管理',
        canceled: '该门店已注销',
        Openingtime: '开通时间',
        founder: '创始人',
        partner: '合伙人',
        SetPassword: '设置密码',
        cancel: '取消',
        confirm: '确认',
        Inputpassword: '输入密码',
        Reconfirm: '再次确认',
        Passwordsuccessfully: '密码设置成功',
    },
    bidumap: {
        choice: '选择',
        SelectCoordinates: '选择坐标',
        confirm: '确认',
        cancel: '取消',
        keyword: '关键词'
    }
}
