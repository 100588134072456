module.exports = {
    language: {
        title: 'Switch to Chinese',
    },
    login: {
        AccountPasswordLogin: 'Login',
        AuthenticationCodeLogin: 'Register',
        phoneNumber: 'Mobile',
        password: 'PIN',
        VerificationCode: 'OTP',
        GetVerificationCode: 'Get OTP',
        SignInNow: 'Login',
        TheVerificationCodeCannotBeEmpty: 'Verification code cannot be empty',
        MobileNumberCannotBeEmpty: 'Mobile number cannot be empty',
        PasswordCannotBeEmpty: 'Password cannot be empty',
        LoginSucceeded: 'Login Succeeded',
        Reacquire: 'Request',
        ResendAfter: 's'
    },
    layout: {
        MerchantManagementCenter: 'Merchant Management Center',
        signout: 'Log Out',
        Goodmorning: 'Good Morning',
        Goodnoon: 'Good Afternoon',
        Goodafternoon: 'Good Afternoon',
        Goodevening: 'Good Evening',
        Goodnight: 'Good Evening',
    },
    error: {
        Sorry: 'Error loading page',
        return: 'Return'
    },
    mendian: {
        Welcome: 'Welcome',
        BasicApplication: 'Basic Application',
        logic: 'Hello there',
        FreeTrial: 'Free trial',
        day: 'days',
        ApplicableScenarios: 'Applicable for',
        solutions: 'We provide holistic solutions for companies.',
        whole: 'All',
        development: 'More solutions under development',
        ComingSoon: 'Coming Soon',
        CreateShop: 'Create Shop',
        EnterStoreName: 'Enter Store Name',
        StoreName: 'Name',
        Successfully: 'Successfully created the shop',
        expire: 'Expiry',
        ChangePassword: 'Change Password',
        Myshop: 'My Shop',
        Eachshop: 'Each shop is operated independently, and members are not shared. Each shop can invite partners to manage together.',
        canceled: 'The store has been canceled',
        Openingtime: 'Created on',
        founder: 'Creator',
        partner: 'Partner',
        SetPassword: 'Create Shop',
        cancel: 'Cancel',
        confirm: 'Confirm',
        Inputpassword: 'Input Password',
        Reconfirm: 'Reconfirm',
        Passwordsuccessfully: 'Password set successfully',
    },
    bidumap: {
        choice: 'Select',
        SelectCoordinates: 'Select Coordinates',
        confirm: 'Confirm',
        cancel: 'Cancel',
        keyword: 'Keyword'
    }
}