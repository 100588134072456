import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import store from './common/store/index.js'

import 'ant-design-vue/dist/antd.less'


import './assets/css/font.css'
import './assets/css/common.css'

Vue.use(Antd)

Vue.config.productionTip = false

import router from './common/router/router';
import * as http from './common/request/request.js';
Vue.prototype.$http = http;
import { common } from './common/mixin/common.js';
Vue.mixin(common);

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'en',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    'zh': require('./components/lang/zh'),
    'en': require('./components/lang/en')
  }
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')